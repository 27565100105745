import { getCurrentHub, Integrations, init, type BrowserOptions } from '@sentry/browser';
import { Dedupe, ExtraErrorData, RewriteFrames, CaptureConsole } from '@sentry/integrations';
import { isFedRamp } from '@atlassian/atl-context';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import {
	SERIALIZABLE_ATTRIBUTES,
	normaliseSentryBreadcrumbs,
} from './handling/normalise-sentry-breadcrumbs.tsx';
import { normaliseSentryEvent } from './handling/normalise-sentry-event.tsx';
import type { Integration } from '@sentry/types';

const SENTRY_URL = 'https://12742a0abbd1484686195dca8ba74be6@o55978.ingest.sentry.io/5988809';
const FRONTEND_RESOURCES = [
	// Our DEV and PROD environments in Micros Static
	/https:\/\/jira-frontend.*\..*\.atl-paas\.net\//,
	// For devs using `yarn start`
	/ngrok/,
	/atlastunnel/,
	// For devs usign `yarn start --no-ngrok`
	/localhost/,
	// Old S3 DEV environment
	/https:\/\/d398cwy4pffc4s\.cloudfront\.net\//,
	// Monolith staging|hello|ap|us environment
	/https:\/\/(d25s903c0x7s6j|dgjotco7y2aa|d9x1z07c483jl|d1vkjhvgh6tvn1).cloudfront.net\//,
	// Old S3 PROD environment
	/https:\/\/d2oj435jmh0qvz\.cloudfront\.net\//,
	// Automation S3 dev|staging|prod environment
	/https:\/\/(d3r49bgl1frqoy|d2v2wiwzx86dg|d283vu6e5qi87p).cloudfront.net\//,
];

// https://docs.sentry.io/platforms/javascript/#removing-an-integration
// Remove Breadcrumbs integration from the Sentry default integrations
// we will re-add it manually with custom options
const getFilteredIntegrations = (integrations: Integration[]) =>
	integrations.filter(({ name }) => name !== 'Breadcrumbs');

// This is used to mark which frames of the stacktrace are part of our app. They will be
// displayed in a different UI in Sentry.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rewriteFramesIteratee = (frame: any) => {
	const curFrame = frame;
	if (curFrame.filename) {
		curFrame.in_app = FRONTEND_RESOURCES.some((resource) => resource.test(frame.filename));
	}
	return curFrame;
};

const getSentryEnvironment = () => {
	if (fg('jira-bypass-sentry-environment-filter')) {
		return 'dev';
	}

	return process.env.NODE_ENV === 'development'
		? 'development'
		: getTenantContext_DEPRECATED_DO_NOT_USE().environment ?? 'no-environment';
};

/**
 * Initializes Sentry for error logging with configurations tailored to the application's environment. It ensures error reports are not duplicated if Sentry is already active, and sets up custom handling for errors and breadcrumbs, enhancing error reporting with specific application context like bundler version, build variant, and user information.
 */
const installGlobalHandler = () => {
	// Check for sentry already enabled to be defensive in case two fragments are loaded in the same page, we
	// don't want duplicated error reports
	const sentryClient = getCurrentHub().getClient();
	if (sentryClient && sentryClient.getOptions().enabled) return;
	let environment = getSentryEnvironment();

	if (environment === 'prod') environment = 'production';
	const tags: {
		bundler?: string;
		variant?: string;
		region: string;
	} = {
		region: getMeta('ajs-region') || 'unknown',
	};

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.BUNDLER_VERSION) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const bundlerVersionTag = window.BUNDLER_VERSION.includes('@')
			? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.BUNDLER_VERSION
			: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				`webpack@${window.BUNDLER_VERSION}`;
		tags.bundler = bundlerVersionTag;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.BUILD_VARIANT) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		tags.variant = window.BUILD_VARIANT;
	}
	const config: BrowserOptions = {
		// This is evaluated against the *first* frame of the stack trace.
		// If it doesn't match, the error is not sent.
		allowUrls: FRONTEND_RESOURCES,
		beforeSend: normaliseSentryEvent,
		// Sentry dom adds more attrs (like title, alt...) to breadcrumbs
		// but they might contain UGC/PII so we need to force strip them
		beforeBreadcrumb: normaliseSentryBreadcrumbs,
		dsn: isFedRamp() ? undefined : SENTRY_URL,
		environment,
		initialScope: {
			tags,
			user: {
				id: getTenantContext_DEPRECATED_DO_NOT_USE().atlassianAccountId ?? undefined,
			},
		},
		integrations: (integrations) => [
			...getFilteredIntegrations(integrations),
			new Integrations.Breadcrumbs({
				// only enable breadcrumbs for dom (UI clicks and inputs)
				// include 'data-test-id', 'data-testid' in the breadcrumbs if they are found
				dom: {
					serializeAttribute: SERIALIZABLE_ATTRIBUTES,
					maxStringLength: 300,
				},
				fetch: false,
				xhr: false,
				console: false,
				history: false,
				sentry: false,
			}),
			new Dedupe(),
			new ExtraErrorData(),
			new RewriteFrames({
				iteratee: rewriteFramesIteratee,
			}),
			...(fg('jira-capture-console-errors-in-sentry')
				? [
						new CaptureConsole({
							levels: ['error'],
						}),
					]
				: []),
		],
		maxBreadcrumbs: 15,

		release: globalThis.BUILD_KEY,
		dist: globalThis.BUILD_VARIANT || 'default',
	};
	init(config);
};

export { installGlobalHandler };

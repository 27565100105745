import React, { useEffect, type ReactNode } from 'react';
import {
	isDesktopSwitchEnabled,
	isSupportedUserAgent,
} from '@atlassian/jira-common-is-mobile-user-agent/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import getMeta from '@atlassian/jira-get-meta';
import initAnalytics from './common/utils/init-analytics/index.tsx';
import { JiraStructuredAnalyticsListener as StructuredAnalyticsListener } from './ui/structured-analytics-listener/JiraStructuredAnalyticsListener.tsx';

type Props = {
	children: ReactNode;
};
/**
 * Enhances child components by providing analytics context, essential for enabling
 * comprehensive analytics across the application. It selectively initializes
 * analytics tools based on organizational and tenant contexts, ensuring
 * relevant data capture. In server environments, it renders children
 * directly for efficiency, while in client environments, it enriches
 * children with structured analytics capabilities for detailed
 * monitoring and insights.
 */
export const AnalyticsProviders = ({ children }: Props) => {
	const tenantContext = useTenantContext();
	const { data: orgId } = useOrgId();
	useEffect(() => {
		if (__SERVER__) {
			return;
		}
		try {
			const success = initAnalytics({
				...tenantContext,
				orgId,
			});
			if (success === undefined) {
				throw new Error(
					'observability.analytics-providers-error: @atlassiansox/analytics-web-client AnalyticsClient in src/common/analytics-web-react/web-client/index.js failed to initialise successfully',
				);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			log.safeErrorWithoutCustomerData('observability.analytics-providers.analytics', e);
		}
	}, [orgId, tenantContext]);
	if (__SERVER__) {
		return <>{children}</>;
	}

	// Temporary analytics for our experiment
	const mobileAnalytics = fg('jira_add_mobile_web_analytics')
		? {
				isMobileUserAgent: isSupportedUserAgent(),
				isDesktopSwitchEnabled: isDesktopSwitchEnabled(),
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				...(fg('jira-fix-firefox-user-agent-detection-2') && { userAgent: navigator?.userAgent }),
			}
		: {};

	/**
	 * As per Jira: Incident Detection and Reliability Uplift we need to add shard and region to the analytics context
	 * This will add shard and region to all analytics events fired throughout the application
	 * we check for __SERVER__ above to ensure SSR does not break with getMeta()
	 */
	const shardAndRegionAnalytics = fg('add_shard_and_region_to_analytic_events_jira')
		? {
				shard: getMeta('ajs-shard') || '',
				region: getMeta('ajs-region') || '',
			}
		: {};

	return (
		<StructuredAnalyticsListener>
			<ContextualAnalyticsData
				attributes={{
					isSPA: __SPA__,

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					variant: window.BUILD_VARIANT,
					...mobileAnalytics,
					...shardAndRegionAnalytics,
				}}
			>
				{children}
			</ContextualAnalyticsData>
		</StructuredAnalyticsListener>
	);
};
export default AnalyticsProviders;
